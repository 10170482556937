import React from "react";

// You can add new objects in the array to render or remove
const socialMedia = [
        {
                id: 0,
                name: "Github",
                url: "https://github.com/vpatel071997/"
        },
        {
                id: 1,
                name: "Linkedin",
                url: "https://www.linkedin.com/in/vaibhavpatel-info/"
        },
        {
                id: 2,
                name: "Instagram",
                url: "https://www.instagram.com/vpatel.design/"
        },
        {
                id: 3,
                name: "Hacker Rank",
                url: "https://www.hackerrank.com/vpatel071997"
        },
]

const Contact = () => {
        return (
                <>
                        <section className="contact">
                                <div className="row contact-inner-section">
                                        <div className="contact-main-text">
                                                <h2>Have an idea?</h2>
                                                <p>Lets get in touch:</p>
                                                <div className="each-contact" data-scroll data-scroll-speed="-1" data-scroll-position="bottom" data-scroll-direction="horizontal">Email:<br />
                                                        <a href="mailto:vpatel071997@gmail.com">vpatel071997@gmail.com</a>
                                                </div>
                                                <div className="each-contact" data-scroll data-scroll-speed="-1" data-scroll-position="bottom" data-scroll-direction="horizontal">Connect Socially: <br />
                                                        {socialMedia.map(eachItem => (
                                                                <div className="social-media-wrap" key={eachItem.id}>
                                                                        <div className="each-social"><a target="_blank" rel="noreferrer" href={eachItem.url}>{eachItem.name}</a></div>
                                                                </div>
                                                        ))}
                                                </div>
                                        </div>
                                </div>
                        </section>
                </>
        );
};

export default Contact;
