import React from "react";

// Sticky header content at the top of screen
const Header = () => {
  return (
    <>
      <div className='header'>
        <div className='container'>
          <div className='row v-center space-between'>
            <div className='logo'>
              <p>VAIBHAV PATEL</p>
            </div>
          </div>
        </div>
      </div>
      <div className="scrolldown"></div>
      <div className="message-button">
        <p>Idea is to bring creativity into digital life.</p>
      </div>
    </>
  );
};
export default Header;
