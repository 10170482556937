import React, { useEffect, useState } from "react";
import gsap from "gsap";

import IntroOverlay from "../components/introOverlay";

// Impor all the components for the index page
import Banner from "../components/banner";
import Slider from "../components/cases";
import Skills from "../components/skills";
import Contact from "../components/contact";

let tl = gsap.timeline();

const homeAnimation = completeAnimation => {

  // gsap animation on load, trigger given components one after the other
  tl.from(".line span", 1.8, {
    y: 300,
    ease: "power4.out",
    delay: 1,
    skewY: 7,
    stagger: {
      amount: 0.3
    }
  })
    .to(".intro-overlay", 1.6, {
      height: 0,
      ease: "expo.inOut",
      stagger: 0.4
    })
    .to(".message-button", {
      opacity: 1,
      stagger: 0.5
    })
    .to(".scrolldown", {
      opacity: 1
    })
    .to(".header", .6, {
      opacity: 0.95,
      ease: "expo.inOut",
      stagger: 0.4
    })
    .to(".intro-overlay", 0, {
      css: { display: "none" },
      onComplete: completeAnimation
    });
};

const Home = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const completeAnimation = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    homeAnimation(completeAnimation);
  }, []);

  return (
    <>
      {animationComplete === false ? <IntroOverlay /> : ""}
      <Banner />
      <Slider />
      <Skills />
      <Contact />
    </>
  );
};

export default Home;
