/**
 * Main application page, It calls all the components for the index page
 */

import React, { useEffect } from "react";
import { gsap } from "gsap";
import locomotiveScroll from "locomotive-scroll";

import "./styles/App.scss";

// Importing all the components
import Cursor from "./components/cursor";
import Header from "./components/header";
import Home from "./pages/home";

// Calling root function
function App() {
  const scrollRef = React.createRef();

  // On load apply gsp animation
  useEffect(() => {
    gsap.to("body", 0, { css: { visibility: "visible" } });
  });

  // On scroll apply locomotive animation
  useEffect(() => {
    new locomotiveScroll({
      el: scrollRef.current,
      smooth: true
    });
  });

  return (
    <>
      <Cursor />
      <Header />
      <div className='App' ref={scrollRef}>
        <Home />
      </div>
    </>
  );
}

export default App;
