import React from "react";

// On load animation component, it works in mixture of scss style
const IntroOverlay = () => {
  return (
    <>
      <div className='intro-overlay'>
      </div>
    </>
  );
};

export default IntroOverlay;
