import { React, useEffect, useRef } from "react";

// This component clones the mouse x & y position and apply custom mouse style
const Cursor = () => {
    const cursorRef = useRef(null);
    const secondaryRef = useRef(null);

    const positionRef = useRef({
        mouseX: 0,
        mouseY: 0,
        destinationX: 0,
        destionationY: 0,
        distanceX: 0,
        distanceY: 0,
        key: -1
    })

    useEffect(() => {
        document.addEventListener('mousemove', (event) => {
            const { clientX, clientY } = event;

            const mouseX = clientX;
            const mouseY = clientY;

            positionRef.current.mouseX = mouseX - secondaryRef.current.clientWidth / 2;
            positionRef.current.mouseY = mouseY - secondaryRef.current.clientHeight / 2;

            cursorRef.current.style.transform = `translate3d(${mouseX - cursorRef.current.clientWidth / 2}px, ${mouseY - cursorRef.current.clientHeight / 2}px, 0)`;
        });
    }, []);

    useEffect(() => {
        const followMouse = () => {
            positionRef.current.key = requestAnimationFrame(followMouse);

            const {
                mouseX,
                mouseY,
                destinationX,
                destionationY,
                distanceX,
                distanceY
            } = positionRef.current;

            if (!destinationX | !destionationY) {
                positionRef.current.destinationX = mouseX;
                positionRef.current.destionationY = mouseY;
            } else {
                positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
                positionRef.current.distanceY = (mouseY - destionationY) * 0.1;
                if (Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) < 0.1) {
                    positionRef.current.destinationX = mouseX;
                    positionRef.current.destionationY = mouseY;
                } else {
                    positionRef.current.destinationX += distanceX;
                    positionRef.current.destionationY += distanceY;
                }
            }
            secondaryRef.current.style.transform = `translate3d(${destinationX}px, ${destionationY}px, 0)`;
        }
        followMouse();
    }, [])

    return (
        <>
            <div className="app-cursor" ref={cursorRef} />
            <div className="secondary-cursor" ref={secondaryRef} />
        </>
    );
};

export default Cursor;